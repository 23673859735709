import React ,{useState} from 'react'

export default function Task() {
    const[count,setCount]=useState(0);
    const Increment =()=>{
        setCount(count+1)
    }
    const Decrement =()=>{
       
        if(count>0){
            
            setCount(count-1)
        }
    }
  return (
    <>
      <div className='d-flex justify-content-center'>
    <p className='fs-1'>{count}</p>
    </div>
    <div className='d-flex justify-content-center'>
        <button className='btn me-3' onClick={Increment} ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-heart-fill" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"/>
</svg></button>
        <button className='btn me-3'onClick={Decrement}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-heartbreak-fill" viewBox="0 0 16 16">
  <path d="M8.931.586 7 3l1.5 4-2 3L8 15C22.534 5.396 13.757-2.21 8.931.586ZM7.358.77 5.5 3 7 7l-1.5 3 1.815 4.537C-6.533 4.96 2.685-2.467 7.358.77Z"/>
</svg></button>
    </div>
   
    
    </>
  )
}
