import React, {useEffect,useState} from 'react'
import axios from 'axios'


function Display() {
  const [Dx,setDx]=useState([])
  useEffect(()=>{
    axios.get('http://localhost:3000/posts')
    .then(res=>setDx(res.data))
    .catch(err=>console.log(err));
  },[])
      return (
        <>
        <table><tr><th>Name</th><th>Email</th><th>Password</th>
       
        </tr>
        {Dx.map((x,index)=>{
          return <tr key={index}>
            <td>{x.id}</td>
            <td>{x.name}</td>
            <td>{x.fname}</td>
            <td>{x.address}</td>
          </tr>
        })}
       
        </table>
        </>
      );


}
export default Display
