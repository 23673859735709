import React from 'react'
import './contact.css';
import Nav from './comp/nav';
import Sidebar from './comp/sidebar';
export default function contact() {
  return (
<>

<Nav/>

<div class="row">
  <div class="col-sm-3"><Sidebar/></div>
  <div class="col-sm-6">
welcome to kalvi</div>
  <div class="col-sm-3"></div>
</div>
</>
  )
}
