import React ,{useState}from 'react'
import avatar from './img/images.png'
import './App.css';
import axios from 'axios';
export default function Form2() {
    const [state,setState] =useState({
        name:"",
        age:"",
        fname:"",
        quali:""
    })
    function handleChange(evt) {
   
        setState({...state,[evt.target.name]: evt.target.value});
       
      }
      const handlesubmit=(e)=>
      {
          e.preventDefault();
          console.log(state);
          axios.post('http://localhost:3000/posts/',state)
          .then(res=>alert("insert Successfully"))
          .catch(err=>console.log(err));
      }
      function Del()
      {
       
        setState({
            name:"",
            age:"",
            fname:"",
            quali:""
        });
      }
     
  return (
    <>
     <div className='container'>
        <div className='row d-flex justify-content-center p-5 mt-5'>
            <div className='col-sm-4  p-3 shadow-lg p-3 mb-5 bg-body rounded'>
               
                <form onSubmit={e=>handlesubmit(e)}>
                    <div className='d-flex justify-content-center'>
                    <img src={avatar} className='img'/>
                    </div>
                    <div class="mb-3">
                        <label className="form-label fw-bold fs-4">Name</label>
                        <input type="text" class="form-control" required placeholder='Enter a Name' value={state.name}  onChange={handleChange} name="name" />
                    </div>
                    <div className="mb-3">
                        <label  className="form-label fw-bold fs-4">Age</label>
                        <input type="number" className="form-control" required placeholder='Enter a Age' value={state.age}  onChange={handleChange} name="age"/>
                    </div>
                    <div className="mb-3">
                        <label  className="form-label fw-bold fs-4">FatherName</label>
                        <input type="text" className="form-control" required placeholder='Enetr a Qualification' value={state.fname}  onChange={handleChange} name="fname"/>
                    </div>
                    <div className="mb-3">
                    <label  className="form-label fw-bold fs-4">Qualification</label>
                       <select  className='form-select'  value={state.quali} name="quali"  onChange={handleChange}required>
                        <option className='selected' value="">--select--</option>
                        <option value="Bsc">Bsc</option>
                        <option value='B.com'>B.com</option>
                        <option value='Engineering'>Engineering</option>
                        <option value='Agri'>Agri</option>
                        <option value='BA'>BA</option>
                       </select>
                    </div>
                    <div className='d-flex justify-content-center'>
                    <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                  
                </form>
                <div className='d-flex justify-content-end'>
                    <button className='btn text-primary text-decoration-underline' onClick={Del} >Clear</button>
                </div>
                </div>

                
        </div>
    </div>
    </>
  )
}
