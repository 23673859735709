import React from 'react'
import Nav from'./comp/nav';
import Foot from './comp/footer';
export default function help() {
  return (
    <>
    <Nav/>

    <Foot/>

    </>
  )
}
