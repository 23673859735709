import React, { useState } from 'react'
import Nav from './comp/nav';

export default function Blog() {
  const [valx,setvalx] =useState(100);
function x()
{
setvalx(20);
}

  return (
    <>
    
    <h1><input type="button" onClick={x} value="click me"></input></h1>
    <p>{valx}</p>
    </>
  )
}

