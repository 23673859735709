import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Task from './task'
import Task2 from './task2'
import EX from './ex'
import About from './about';
import Contact from './contact';
import Models from './Model';
import Help from './help';
import Dash from './dash';
import Fet from './fet';
import Jso from './jso';
import Form from './form'
import Form2 from './form2';
import Sapi from './sapi'
import Oncc from './oncc'
import Insertjson from './insertjson'
import Display from './display'
import Disp from './disp'
import Ecart from './ecart'
import Addc from './addtocart'
import Payment from './payment'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router,Routes,Route} from "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<Router>
<Routes>
<Route exact path='/form2' element={<Form2/>}></Route>
<Route exact path='/task' element={<Task/>}></Route>
<Route exact path='/' element={<App/>}></Route>
<Route exact path='/about' element={<About/>}></Route>
<Route exact path='/contact' element={<Contact/>}></Route>
<Route exact path='/Model' element={<Models />}></Route>
<Route exact path='/help' element={<Help />}></Route>
<Route exact path='/dash' element={<Dash/>}></Route>
<Route exact path='/sapi' element={<Sapi/>}></Route>
<Route exact path='/insert' element={<Insertjson/>}></Route>
<Route exact path='/display' element={<Display/>}></Route>
<Route exact path='/disp' element={<Disp/>}></Route>
<Route exact path='/ecart' element={<Ecart/>}></Route>
<Route exact path='/addtocart' element={<Addc/>}></Route>
<Route exact path='/payment' element={<Payment/>}></Route>
</Routes>

</Router>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
