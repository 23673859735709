import './App.css';
import bike3 from'./img/fz.webp';
import bike1 from './img/R16.webp';
import bike2 from './img/rex.jpg';
import Nav from './comp/nav';
import Foot from './comp/footer';
function App() {
  return (
    <>
    <Nav/>
<section className='content'>
  <div className='container mt-3'>
    <div className='row'>
  <div className='col-sm-4'>
      <div className="text-dark">
      <img src={bike1} class="card-img-top" alt="..."/>
       <div class="card-body">
        <h5 class="card-title fs-3">R15</h5>
        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
        <a href="/" class="btn btn-outline-secondary">Details</a>
      </div>
      </div>

    
  </div>
      <div className='col-sm-4 '>
          <div className=" text-dark">
          <img src={bike2} class="card-img-top" alt="..."/>
          <div class="card-body">
            <h5 class="card-title fs-3">RX-100</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            <a href="/" class="btn btn-outline-success">Details</a>
          </div>
          </div>
      </div>
      <div className='col-sm-4'>
          <div className="text-dark">
          <img src={bike3} class="card-img-top" alt="..."/>
          <div class="card-body">
            <h5 class="card-title fs-3" >FZ-v3</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            <a href="/" class="btn btn-outline-dark">Details</a>
          </div>
          </div>
      </div>
    </div>
  </div>
</section>
<div className='container '>
<div class="accordion mt-5" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button bg-warning text-light fw-bold fs-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        R15
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed bg-danger text-light fw-bold fs-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
       FZ-3
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed bg-primary text-light fw-bold fs-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        RX-100
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
</div>
</div>
<Foot/>
</>
    
  );
}

export default App;
