import axios from 'axios';
import './App.css'
import React, { useEffect, useState } from 'react'
import {Link,useNavigate,useParams} from 'react-router-dom'

//display the json records
export default function Disp() {
    const navigate=useNavigate();
    
    const [joo,setJoo]=useState([]);
    useEffect(()=>{
        axios.get("http://localhost:3000/posts")
        .then(res=>setJoo(res.data))
        .catch(err=>console.log(err));
    },[])

//delete the records through the delete button
    const hdelete=(id)=>
  {
    const confirm=window.confirm("Would you like to Delete?"+id);
    if(confirm)
    {
      axios.delete('http://localhost:3000/posts/'+id)
      .then(res=>{navigate(0)})
      .catch(err=>console.log(err));


   }
  }
// get values to modal
  const [newvalue,newchange] = useState({
    id:"",
    name:"",
    age:"",    
    fname:"",
    quali:""
  })

  const hget=(hhh)=>
  {
    axios.get('http://localhost:3000/posts/'+hhh)
    .then(res=> newchange(res.data))
    .catch(err=>console.log(err));
   
  }
// update records

function uhandlechange(evt)
    {
      newchange({...newvalue,[evt.target.name]:evt.target.value});
    }
    const hsubmit=(e)=>{

      e.preventDefault();
      console.log(newvalue);
      axios.put('http://localhost:3000/posts/'+newvalue.id,newvalue)
      .then(res=>alert("updated successfully"))
      .then(res=>{navigate(0)})
      .catch(err=>console.log(err));
    }




  return (
     <>
     <table className='table table-danger table-striped table-bordered'>
        <tr className='bg-warning'>
        <th className='text-center'>Id</th>
          <th className='text-center'>Name</th>
            <th className='text-center'>Age</th>
            <th className='text-center'>FatherName</th>
            <th className='text-center'>Qualification</th>
            <th colspan="2" className='text-center'>action</th>
        </tr>
        
            <tbody>
                {
                    joo.map((ans,index)=>{
                        return <tr key={index}>
                             <td>{ans.id}</td>
                            <td>{ans.name}</td>
                            <td>{ans.age}</td>
                            <td>{ans.fname}</td>
                            <td>{ans.quali}</td>
                            <td>
<button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={e2=>hget(ans.id)}>
  Edit
</button>

<div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title fs-1">Edit...<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-emoji-smile" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.5 3.5 0 0 0 8 11.5a3.5 3.5 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5"/>
</svg></h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form onSubmit={(e)=>hsubmit(e)}>
      <div className="modal-body bg-danger text-light">
     

          <div className="mb-3">
            <label className="form-label">Id</label>
            <input type="text" className="form-control" id="exampleInputEmail1" name="id" value={newvalue.id} onChange={uhandlechange} readOnly/>
          </div>

            <div className="mb-3">
            <label className="form-label">Name</label>
            <input type="text" className="form-control" id="exampleInputEmail1" name="name" value={newvalue.name} onChange={uhandlechange}/>
          </div>
          
          <div className="mb-3">
            <label className="form-label">Age</label>
            <input type="number" className="form-control" id="exampleInputEmail1" name="age" value={newvalue.age} onChange={uhandlechange}/>
          </div>

          <div className="mb-3">
            <label className="form-label">FatherName</label>
            <input type="text" className="form-control" id="exampleInputEmail1" name="fname" value={newvalue.fname} onChange={uhandlechange}/>
          </div>

          
          <div className="mb-3">
            <label className="form-label">Qualification</label>
           
            <select  className='form-select'  value={newvalue.quali} name="quali" onChange={uhandlechange}required>
                        <option className='selected' value="">--select--</option>
                        <option value="Bsc">Bsc</option>
                        <option value='B.com'>B.com</option>
                        <option value='Engineering'>Engineering</option>
                        <option value='Agri'>Agri</option>
                        <option value='BA'>BA</option>
                       </select>
          </div>

      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="submit" className="btn btn-primary" >Save changes</button>
        
      </div>
      </form>
    </div>
  </div>
</div></td>
                            <td><button className='btn btn-danger dum' onClick={e=> hdelete(ans.id)}>Delete</button></td>
                         </tr>
                    })
                }
            </tbody>
      
     </table>
     </>
  )
}
