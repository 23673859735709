import React, { useState } from 'react';


function App() {
  const [cart, setCart] = useState([]);
  const products = [
    { id: 1, name: 'Product 1', price: 10 },
    { id: 2, name: 'Product 2', price: 15 },
    { id: 3, name: 'Product 3', price: 20 },
  ];


  const addToCart = (product) => {
    setCart([...cart, product]);
  };


  return (
    <div>
      <h1>Shopping Cart</h1>
      <ul>
        {products.map((product) => (
          <li key={product.id}>{product.name} - ${product.price} 
            <button onClick={() => addToCart(product)}>Add to Cart</button>
          </li>
        ))}
      </ul>


      <h2>Cart</h2>
      <ul>
        {cart.map((item) => (
          <li key={item.id}>
            {item.name} - ${item.price}
          </li>
        ))}
      </ul>
    </div>
  );
}


export default App;
