import React, {useState} from 'react'
import axios from 'axios'


function Insertjson() {
    const [state, setState] = React.useState({
      
      id: "",
      name:"",
      fname:"",
      address:""
      })

      function handleChange(evt) {
   
        setState({...state,[evt.target.name]: evt.target.value});
       
      }


      const handlesubmit=(e)=>
      {
          e.preventDefault();
          console.log(state);
          axios.post('http://localhost:3000/posts/',state)
          .then(res=>console.log("insert Successfully"))
          .catch(err=>console.log(err));
      }

      return (
        <form onSubmit={e=>handlesubmit(e)}>
          <label>
           UserId
            <input
              type="text"
              name="id"
              value={state.id}
              onChange={handleChange}
            />
          </label>
          <label>
           Id
            <input
              type="text"
              name="name"
              value={state.name}
              onChange={handleChange}
            />
          </label>
          <label>
        Title
            <input
              type="text"
              name="fname"
              value={state.fname}
              onChange={handleChange}
            />
          </label>




          <label>
     Body
            <input
              type="text"
              name="address"
              value={state.address}
              onChange={handleChange}
            />
          </label>




          <input type="submit" />
        </form>
      );
}


export default Insertjson
