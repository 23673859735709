import React, { useState } from 'react';
import { Link } from "react-router-dom"
import Payment from './payment';
function App() {
  const [cart, setCart] = useState([]);
  const [total, settotal] = useState(0);
  const products = [
    { id: 1, name: 'Product 1', price: 10 ,im:'https://m.media-amazon.com/images/I/71SP5UHjDAL._AC_UL320_.jpg'},
    { id: 2, name: 'Product 2', price: 15 ,im:'https://m.media-amazon.com/images/I/710SI8g1RaL._AC_UL320_.jpg'},
    { id: 3, name: 'Product 3', price: 20,im:'https://m.media-amazon.com/images/I/51ITc0M-viL._AC_UL320_.jpg' },    
    { id: 4, name: 'Product 4', price: 10 ,im:'https://m.media-amazon.com/images/I/71SP5UHjDAL._AC_UL320_.jpg'},
    { id: 5, name: 'Product 5', price: 15 ,im:'https://m.media-amazon.com/images/I/710SI8g1RaL._AC_UL320_.jpg'},
    { id: 6, name: 'Product 6', price: 20,im:'https://m.media-amazon.com/images/I/51ITc0M-viL._AC_UL320_.jpg' },
    { id: 7, name: 'Product 7', price: 10 ,im:'https://m.media-amazon.com/images/I/71SP5UHjDAL._AC_UL320_.jpg'},
  ];


  const addToCart = (product) => {
    setCart([...cart, product]);
    settotal(total+product.price)
    
  };
  const dataToPass = { name: 'John Doe', age: 25 };
  return (
   
    
    <div>
      <h1>Shopping Cart</h1>
      <div className='row'>
        {products.map((product) => (
          <div class='col-sm-3' key={product.id}>  
            <div class="card mb-2">
                <img src={product.im} class="card-img-top" alt="..."/>
                <div class="card-body">
                    <h5 class="card-title">{product.name} - ${product.price}</h5>
                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                   <button className='btn btn-primary' onClick={() => addToCart(product)}> Add to cart</button>
                  
                </div>
           </div>
          </div>
        ))}
      </div>
      <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div>
     These are the detailes about your cart
     <h2>Cart</h2>
      <ul>
        {cart.map((item) => (
          <li key={item.id}>
            {item.name} - ${item.price}
          </li>
           
        ))}
      </ul>
    </div>
  Total Amount $: {total}<br/>
 
  <Link to="/payment" state={{ amount: total }}>
  Next Step
</Link>
 
  </div>
</div>

<a class="btn btn-primary"  data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
  Go to cart</a>
    </div>
    
  );
}

export default App;
