import React from 'react'
import Foot from'./comp/footer'
import Nav from './comp/nav';
export default function about() {
  return (
   <>
 <Nav/>
 
   <Foot/>
   </>
  )
}
