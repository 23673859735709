import { useEffect, useState } from "react";
import "./App.css";
function Sapi() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [emailSearchTerm, setEmailSearchTerm] = useState("");
  const [websiteSearchTerm, setWebsiteSearchTerm] = useState("");


  useEffect(() => {
    setLoading(true);
    fetch("https://jsonplaceholder.typicode.com/users")
      .then((response) => response.json())
      .then((json) => setUsers(json))
      .finally(() => {
        setLoading(false);
      });
  }, []);


  const filteredUsers = users.filter(
    (user) =>
      user.email.toLowerCase().includes(emailSearchTerm.toLowerCase()) &&
      user.website.toLowerCase().includes(websiteSearchTerm.toLowerCase())
  );


  return (
    <div className="App">
      <div className="container py-5">
        <div className="text-center mb-4">
          <h1 className="display-4">User List</h1>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search by email"
                value={emailSearchTerm}
                onChange={(e) => setEmailSearchTerm(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search by website"
                value={websiteSearchTerm}
                onChange={(e) => setWebsiteSearchTerm(e.target.value)}
              />
            </div>
            {loading ? (
              <div className="text-center">Loading...</div>
            ) : (
              <table className="table table-bordered table-striped custom-table">
                <thead className="thead-custom">
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Website</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.map((user) => (
                    <tr key={user.id}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.phone}</td>
                      <td>{user.website}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}


export default Sapi;
